<template>
    <b-row>
        <h2 class="pl-1">Транзакции</h2>

        <!--  BEFORE TABLE  -->
        <div class="d-flex justify-content-between col-12">
            <b-col md="4" class="my-1 px-0">
                <b-form-group class="mb-0">
                    <b-input-group size="md">
                        <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Искать" />
                        <b-input-group-append>
                            <b-button :disabled="!filter" @click="filter = ''">
                                Очистить
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </b-col>
        </div>

        <!--  TABLE  -->
        <b-col cols="12">
            <b-table striped hover responsive sort-icon-left :busy="isBusy" :items="items" :fields="fields"
                @sort-changed="handleSortChange">
                <template #table-busy>
                    <div class="text-center text-primary my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong class="ml-1">Идет загрузка ...</strong>
                    </div>
                </template>

                <template #cell(actions)="data">
                    <div class="d-flex float-right">

                        <!--  DEACTIVATE  -->
                        <div class="ml-1" v-if="data.item.status === 'pending'">
                            <!--  approve modal  -->
                            <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" v-b-modal="`modal-${data.item.id}-accept`"
                                variant="outline-success" class="delete__btn mr-1">
                                <feather-icon icon="CheckIcon" size="18" />
                            </b-button>
                            <b-modal :id="`modal-${data.item.id}-accept`" body-class="deactivate-btn_modal"
                                title="Вывести кэшбек" hide-header-close centered>
                                Вы хотите разрешить вывод кешбека?

                                <template #modal-footer>
                                    <b-button variant="danger btn-sm" @click="
                                        $bvModal.hide(
                                            `modal-${data.item.id}-accept`
                                        )
                                        ">
                                        Отменить
                                    </b-button>

                                    <b-button variant="success btn-sm" @click="
                                        processCashback(
                                            data.item.id,
                                            'confirm')
                                        ">
                                        Да
                                    </b-button>
                                </template>
                            </b-modal>
                            <!--  cancel modal  -->
                            <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" v-b-modal="`modal-${data.item.id}`"
                                variant="outline-danger" class="delete__btn">
                                <feather-icon icon="XIcon" size="18" />
                            </b-button>
                            <b-modal :id="`modal-${data.item.id}`" body-class="deactivate-btn_modal"
                                title="Вывести кэшбек" hide-header-close centered>
                                Вы хотите отказать в выводе кешбека?

                                <template #modal-footer>
                                    <b-button variant="danger btn-sm" @click="
                                        $bvModal.hide(
                                            `modal-${data.item.id}`
                                        )
                                        ">
                                        Нет, отменить
                                    </b-button>

                                    <b-button variant="success btn-sm" @click="
                                        processCashback(
                                            data.item.id,
                                            'cancel'
                                        )
                                        ">
                                        Да, отказать
                                    </b-button>
                                </template>
                            </b-modal>

                        </div>
                    </div>
                </template>

                <template #cell(created_at)="data">
                    <span>{{
                        new Date(data.item.created_at).toLocaleDateString("ru")
                    }}</span>
                </template>
            </b-table>
        </b-col>

        <!--  PAGINATION  -->
        <b-col cols="12" class="mb-3 d-flex justify-content-between align-items-center">
            <b-form-select class="float-right col-1" v-model="pagination.perPage" placeholder="Выберите"
                :options="pagination.perPageOptions">
            </b-form-select>
            <b-pagination v-model="pagination.page" :total-rows="pagination.total" :per-page="pagination.perPage"
                align="center" size="sm" class="my-0" />
        </b-col>
    </b-row>
</template>

<script>
import {
    BTable,
    BRow,
    BCol,
    BFormGroup,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BSpinner,
    BFormSelect,
} from "bootstrap-vue";
import Cashback from "@/services/cashback";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
    paginationData,
    paginationWatchers,
    paginationHelperMethods,
} from "@/util/pagination-helper";
import permissionComputeds from "@/util/permissionComputeds";
const cApi = new Cashback();

export default {
    name: "AppDealers",
    components: {
        BTable,
        BRow,
        BCol,
        BFormGroup,
        BPagination,
        BInputGroup,
        BFormInput,
        BInputGroupAppend,
        BButton,
        BSpinner,
        BFormSelect,
        ToastificationContent,
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            apiEntry: "dealers",
            name: null,
            isBusy: false,
            filter: null,
            fields: [
                {
                    key: "id",
                    label: "ID",
                },
                {
                    key: "card_id",
                    label: "Card ID",
                },
                {
                    key: "user",
                    label: "Владелец",
                    formatter: v => v.first_name + ' ' + v.last_name
                },
                {
                    key: "amount",
                    label: "Сумма",
                },

                {
                    key: 'user.balance',
                    label: 'Баланс'
                },
                {
                    key: 'created_at',
                    label: 'Баланс',
                    formatter: v => new Date(v)
                },
                {
                    key: 'actions'
                }
            ],
            items: [],
            pagination: paginationData(),
        };
    },
    watch: paginationWatchers("getData"),

    async mounted() {
        this.setParams();
        await this.getData();
        // if (this.isDeleteAvailable || this.isUpdateAvailable)
        //     this.fields.push({
        //         key: "crud_row",
        //         label: " ",
        //     });
    },

    computed: {
        ...permissionComputeds("dealer"),

        showPagination() {
            return (
                this.pagination.total > this.pagination.perPage && !this.isBusy
            );
        },
    },

    methods: {

        ...paginationHelperMethods("", {}),
        showToast(variant, text, icon) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: text,
                    icon: icon,
                    variant,
                },
            });
        },

        async getData() {
            this.isBusy = true;
            await cApi
                .fetchAll(this.getParams())
                .then((res) => {
                    this.items = res.data.data;
                    this.pagination.total = res.data.total;
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    this.isBusy = false;
                });
        },

        processCashback(id, resolution) {
            cApi[resolution](id)
                .then(() => {
                    this.getData();
                    if (resolution === 'confirm') {
                        this.showToast(
                            "success",
                            "Кэшбек разрешен к выводу!",
                            "CheckIcon"
                        );
                    } else {
                        this.showToast(
                            "success",
                            "Вывод кешбека отменен!",
                            "CheckIcon"
                        );
                    }
                })
                .catch((error) => {
                    console.error(error);
                    this.showToast("danger", "Что-то пошло не так!", "XIcon");
                });
        },
    },
};
</script>

<style lang="scss" scoped>
[dir] .dropdown-item {
    padding: 0;
}

//::v-deep .table thead th[aria-colindex="1"] {
//  width: 70px;
//}

.validation__red {
    color: red;
    font-size: 12px;
    display: block;
    margin-bottom: 1rem;
}

.form-group {
    margin-bottom: 6px;
}
</style>
