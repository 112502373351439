import { axiosInstance } from '@/services/core/base';
import Core from '@/services/core';

class Cashback extends Core {
  constructor(axios = axiosInstance) {
    super(axios);
  }

  fetchAll(params = '') {
    return this.get('withdrawal-transaction' + (params ? '?' + params : ''));
  }
  confirm(id) {
    return this.put(`withdrawal-transaction/${id}/confirm`);
  }
  cancel(id) {
    return this.put(`withdrawal-transaction/${id}/cancel`);
  }
  // fetchClients(params = '') {
  //   return this.get('client' + (params ? '?' + params : ''));
  // }

  // fetchClient(id) {
  //   return this.get('client/' + id);
  // }
  // updateClientBalance(id, amount) {
  //   return this.put('client/' + id, { balance: amount });
  // }
}

export default Cashback;
